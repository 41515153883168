<template>
  <div class="info_warp">
    <div class="title">术康-隐私协议</div>
    <div class="inner">
      <p>更新日期：2020年【4】月【3】日</p>
      <p class="bold">提示条款</p>
      <p>
        成都尚医信息科技有限公司（包括本公司之分公司、子公司，以下简称“我们”、“尚医科技”）深知个人信息对您的重要性。我们将按照法律法规的要求，采取相应的安全保护措施，尽力保护您的个人信息安全可控。鉴于此，我们制定了本《隐私政策》。</p>
      <p>本政策适用于术康APP。术康APP系尚医科技向您提供的应用程序。</p>
      <p class="bold">特别提示：本政策不适用于其他第三方向您提供的产品和服务，第三方向您提供的产品或服务适用于该等第三方向您另行说明的隐私政策。</p>
      <p>
        <span class="bold">在您使用我们提供的产品或服务前，请您务必仔细阅读并透彻理解本政策，尤其是以粗体/下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。</span>
        如您对本政策存在任何疑问、建议或意见的，您可以通过我们提供的联系方式与我们联系。
      </p>
      <p>本政策将帮助您了解以下内容：</p>
      <p>一、我们如何收集和使用您的个人信息</p>
      <p>二、我们如何使用Cookie和同类技术</p>
      <p>三、我们如何共享、转让、公开披露您的个人信息</p>
      <p>四、我们如何保存和保护您的个人信息</p>
      <p>五、您如何管理您的个人信息</p>
      <p>六、我们如何处理儿童的个人信息</p>
      <p>七、您的个人信息如何跨境转移</p>
      <p>八、本政策如何更新</p>
      <p>九、如何联系我们</p>

      <h3>一、我们如何收集和使用您的个人信息</h3>
      <p>在您使用我们的产品和/或服务时，我们需要或可能需要收集、使用您的个人信息。根据您使用的产品和/或服务的功能区分，我们将根据如下原则收集、使用您的个人信息：</p>
      <p class="bold">1.为实现向您提供的产品和/或服务的基本功能，您须授权我们收集实现该功能的必要信息。如您拒绝提供相应的信息，您将无法正常使用我们的产品和/或服务。</p>
      <p class="bold">
        2.为实现向您提供的产品和/或服务的附加功能，您可以选择是否授权我们收集、使用相应的个人信息。如果您拒绝提供相应的个人信息，您将无法使用相关附加功能，但不会影响您正常使用基本功能及已经授权的其他附加功能</p>
      <h4>（一）您直接提供或自动采集的个人信息</h4>
      <p>为实现本政策下述各项功能，我们将收集和使用您的个人信息：</p>
      <p class="bold">1.帮助您注册/登录术康</p>
      <p>您可以通过“微信登录”或“手机登录”方式注册/登录术康。
        <span class="bold">您在注册/登录术康时，您需向我们提供您的手机号及验证码。如您希望通过微信登录术康，您还需额外向我们提供您的微信昵称、头像、地区、性别，或其他拟使用的头像和昵称。</span>
      </p>
      <p class="bold">在您首次使用术康时，为了向您提供更加个性化的服务，您需向我们提供您的性别、生日、身高、体重及您感兴趣的资讯或疾病。</p>
      <p class="bold">2.信息/资讯提供服务</p>
      <p class="bold">在您使用我们服务的过程中，基于识别程序及账户异常、向您提供更优质且符合您需求的页面和搜索结果，我们可能会自动收集您的如下信息并存储为网络日志信息，包括：</p>
      <p>
        <span class="bold">设备信息。</span>
        我们会在您使用的过程中识别您的设备相关信息，包括 <span class="underLine">设备号、操作系统版本号、设备设置、设备识别符、设备环境、移动应用列表等。</span>
      </p>
      <p>
        <span class="bold">服务日志信息。</span>
        当您使用我们的服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存，可能
        <span class="underLine">包括浏览、点击查看、搜索查询等轨迹信息以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。</span>
      </p>
      <p class="bold">请您注意，为了向您提供更加优质、符合您个性化需求的服务，我们会根据您的设备信息和您使用服务时我们收集的服务日志信息判断您的偏好，并将由此得出的偏好标签用于展示、推送信息和可能的商业广告。</p>
      <p class="underLine">如果您不希望继续接收我们向您发送的商业广告，可以通过我们发送商业广告时提供的退出方式或其他我们告知的方式进行退订或关闭。</p>
      <p class="bold">3.定制处方服务</p>
      <p>您可以根据自己需要定制您的处方。为了帮助您定制符合您需求的处方，
        <span class="bold">我们需确认您的基本信息，包括您的性别、生日、身高、体重。同时，您需根据您的实际情况完成我们的风险因素筛查问卷。</span>
        我们将对您填写的相关信息进行分析，并据此提供相应的分析报告。
      </p>
      <p>如您使用了我们的心率带产品，我们还将收集您的【心率数据】。</p>
      <p class="bold">4.为您提供产品购买服务</p>
      <p>您可以通过健康商城购买我们的产品和/或服务。
        <span class="bold">为了帮助您完成下单和支付，您需向我们提供您的收货地址信息，包括收货人姓名、手机号、收货地址、邮编。此外，该订单还可能自动生成产品和/或服务名称、数量、订单号、订单创建时间、您应支付的金额、支付时间等。为了帮助您顺利完成订单并提供购买和售后服务，我们将一并收集这些信息。</span>
      </p>
      <p class="bold">5.为您提供运动记录、动态日记等服务</p>
      <p class="bold">您可以通过术康记录您的运动时长、天数及累计卡路里。此外，我们会根据用户运动时长向您提供运动排行榜。如您运动时长达到上榜标准的，我们将在运动排行榜展示您的用户名及运动时长。</p>
      <p class="bold">您可以在术康发布您的动态，为了发布图片，您需授权我们访问您的相册。如您希望显示您的位置，您还需授权我们访问您的定位权限。</p>
      <p class="bold">6.联系我们</p>
      <p>当您与我们联系、提出订购或售后服务请求或提出争议解决请求时，
        <span class="bold">为了保障您的账户安全，我们可能需要您提供您的必要个人信息以核验您的身份。</span>
      </p>
      <p>为了向您提供更优质的服务、与您联系、记录您的问题处理进展及结果等，
        <span class="bold">我们可能会保存您的联系方式、通信记录及通信内容、相关订单信息及其他您提供的相关信息等。</span>
      </p>
      <p class="bold">7.保障安全及系统稳定</p>
      <p>为提高您使用我们的产品与/或服务时系统的安全性和稳定性，保护您的账户安全，
        <span class="bold">我们会收集您的设备信息、服务日志信息及其他与系统安全和稳定相关的信息，嵌入我们开发或供应商提供的SDK收集上述信息，并可能使用和整合您在其他场景向我们提供的及我们关联公司收集的取得您授权或根据法律可以共享的信息，</span>
        以便我们综合判断您的账户风险，在发现异常时，及时予以排查。
      </p>
      <h4>（二）授权同意的例外</h4>
      <p>根据法律法规及相关国家标准，以下情形中，我们可能依法收集、使用您的个人信息而无需征得您的授权同意：</p>
      <p>1.与国家安全、国防安全有关的；</p>
      <p>2.与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>3.与犯罪侦查、起诉、审判和判决执行等有关的；</p>
      <p>4.出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p>5.所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
      <p>6.从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p>7.根据您的要求签订和履行合同所必需的；</p>
      <p>8.尚医科技或其委托的第三方供应商用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品和/或服务的故障；</p>
      <p>9.为新闻单位且其在开展合法的新闻报道所必需的；</p>
      <p>10.为学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； </p>
      <p>11.法律法规规定的其他情形。</p>
      <h4>（三）个人信息使用规则</h4>
      <p>我们基于本政策收集的个人信息将会严格按照本政策所告知的目的进行使用。如我们超出与收集时所告知的目的及具有直接或合理关联的目的范围使用您的个人信息，我们将在使用您的个人信息前，再次告知您我们的使用目的，并征得您的同意。
        我们会基于以下目的使用您的个人信息：</p>
      <p class="bold">1.根据“您直接提供或自动采集的个人信息”中告知的场景中的特定目的使用您的个人信息。</p>
      <p>
        <span class="bold">2.为您提供安全保障。</span>
        为保障我们产品和/或服务的安全性，我们可能使用您的信息用于身份验证、安全防范、诈骗检测等，以预防、发现、调查欺诈、危害安全、非法或违反与我们的协议、政策或规则的行为，以保护您、我们的其他用户、我们或尚医科技关联方、合作方及社会公众的合法权益。
      </p>
      <p>
        <span class="bold">3.与您沟通。</span>
        我们会使用您提供的联系信息，包括您的手机号码、电子邮件、社交账号及联系地址及其他可以联系到您的信息，直接与您沟通或互动。如，如果我们产品和/或服务将发生变更，我们可能会提前通知您，或邀请您参加我们的体验活动等。
      </p>
      <p>
        <span class="bold">4.为您展示和推送定制内容。</span>
        根据收集的个人信息，我们会向您展示和推送与您相关程度更高的搜索结果、信息、广告等。
        <span class="bold">为了您获得更好的体验、改善我们的产品和/或服务质量，我们会在符合法律法规要求的前提下，将我们于某产品和/或服务中收集的信息用于另一项产品和/或服务中向您展示和推送定制内容。</span>
      </p>
      <p>
        <span class="bold">5.改善我们的产品和/或服务，开展内部审计、数据分析和研究。</span>
        我们将使用收集的信息改善我们的产品和/或服务，如评估、维护和改进产品和服务的性能，开发新的产品和服务、提供客户支持等。此外，我们还可能将信息用于内部审计、数据分析和研究，在用于该等目的时，我们不会披露任何您的身份识别信息。
      </p>

      <h3>二、我们如何使用Cookie和同类技术</h3>
      <p>为确保应用程序正常运转、为您提供更轻松的访问体验、向您推荐感兴趣的内容，我们会在您的计算机或移动设备上存储Cookie、Flash
        Cookie，或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（以下统称“Cookie”）。借助于Cookie，网站、浏览器能够存储您的偏好等数据。</p>
      <p>
        我们不会将Cookie用于本政策所述目的之外的任何用途。如果您的浏览器或浏览器提供的服务允许，您可根据自己的偏好管理或拒绝Cookie。有关详情，请参见AboutCookies.org。但如果您这么做，则需要在每一次访问我们的网站或应用程序时亲自更改用户设置。</p>
      <h4>（一）Cookie同类技术</h4>
      <p>除Cookie外，我们还会在网站/应用程序上使用其他同类技术，具体如下：</p>
      <p>1. TalkingData:
        TalkingData是第三方数据智能服务商。TalkingData提供SDK供移动应用使用。SDK收集的信息包含：【Android】设备品牌、型号、软件版本等基础信息及其应用列表信息。【IOS】设备品牌、型号、软件版本等基础信息。网路信息：设备连接WiFi和基站信息。除此以外SDK都不会收集您的账号、密码、身份等个人隐私信息。</p>
      <p>2. Bugly：腾讯Bugly，为移动开发者提供专业的异常上报和运营统计，帮助开发者快速发现并解决异常，及时跟进用户反馈。Bugly提供SDK供移动应用使用。Bugly只会在应用程序出现崩溃的情况下上报APP
        Crash日志。该日志为android或ios系统自身的崩溃日志信息。可能会包含设备的系统版本、CPU架构、设备机型等信息。除此以外SDK都不会收集您的账号、密码、身份等个人隐私信息。</p>

      <h3>三、我们如何共享、转让、公开披露您的个人信息</h3>
      <h4>（一）共享</h4>
      <p>原则上，我们不会与任何其他公司、组织和个人分享您的个人信息，但以下情况除外：</p>
      <p>1.在征得您明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。</p>
      <p>2.我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。</p>
      <p class="bold">3.与我们的关联公司共享：您的个人信息可能会与尚医科技的关联公司共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。关联公司如要改变个人信息的处理目的，将再次征求您的授权同意。
        我们的关联公司包括：【】。
      </p>
      <p>4.与授权合作伙伴共享：仅为实现本政策中声明的目的，我们的某些服务将由授权合作伙伴提供。
        <span class="bold">我们可能会与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</span>
      </p>
      <p>目前，我们的授权合作伙伴包括以下两大类型：</p>
      <p>
        1）广告、分析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴分享。我们会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行去标识处理，以便它不会识别您个人。该类合作伙伴可能将我们所提供的上述信息与其合法收集的其他数据进行结合，以实现我们委托的广告或分析等服务。</p>
      <p>
        2）2)供应商、服务提供商和其他合作伙伴。我们可能将信息发送给在全球范围内支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。</p>
      <p>对我们与之共享个人信息的公司、组织和个人，我们会要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
      <h4>（二）转让</h4>
      <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
      <p>1.在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
      <p>2.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
      <h4>（三）公开披露</h4>
      <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
      <p>1.获得您明确同意后；</p>
      <p>2.基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
      <h4>（四）共享、转让、公开披露个人信息时事先征得授权同意的例外</h4>
      <p>以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：</p>
      <p>1.与国家安全、国防安全有关的；</p>
      <p>2.与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>3.与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的；</p>
      <p>4.出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
      <p>5.您自行向社会公众公开的个人信息；</p>
      <p>6.从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。</p>
      <p>7.法律法规规定的其他情形。</p>
      <p>
        <span class="bold">请您知悉，根据现行法律法规的规定，若我们对个人信息采取技术措施和其他必要措施进行处理，使得接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。</span>
      </p>
      <h3>四、我们如何保存和保护您的个人信息</h3>
      <h4>（一）个人信息保存</h4>
      <p>我们在中华人民共和国境内收集的个人信息原则上将在中华人民共和国境内存储，<span class="bold">但在适用法律法规允许的情况下，我们也可能将您的个人信息存储于中华人民共和国境外。我们将在实现本政策所述目的所需的期限内保留您的个人信息，法律法规有特别要求的，我们将按照法规法规的强制要求对您的个人信息进行保留。</span>在确定保留期限时，我们会根据如下标准进行判断：
      </p>
      <p>1.为您提供产品和/或服务的查询、投诉及售后服务；</p>
      <p>2.保障我们为您提供的产品和/或服务的安全及质量；</p>
      <p>3.您是否对保留期限作出特别授权。</p>
      <p><span class="bold">当您的个人信息超出保留期限后，我们会根据届时适用的法律法规的要求删除您的个人信息或进行匿名化处理。</span></p>
      <h4>（二）个人信息保护</h4>
      <p>1.我们将按符合业界标准、合理可行的安全保护措施来保护您的个人信息，以防止您的个人信息被未经授权访问、公开披露、非法使用、篡改、损坏、删除或销毁。我们会采取一切合理可行的措施，保护您的个人信息。</p>
      <p>2.我们已经采取了必要的管理措施保护您的个人信息，包括对供应商的安全审计支付、员工内部培训、个人信息访问和使用权限管理等。</p>
      <p>3.互联网并非绝对安全的环境，而且电子邮件、即时通讯、及与其他应用程序内附带的交流方式并未加密，我们强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助我们保证您的账号安全。</p>
      <p>
        4.在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知时，我们会采取合理、有效的方式发布公告。</p>
      <p>同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</p>
      <h3>五、您如何管理您的个人信息</h3>
      <p>根据适用的法律法规，您有权访问、更正、删除您的个人信息，管理您的授权及注销您的个人账户。</p>
      <h4>（一）访问/更正您的个人信息</h4>
      <p>您可已在“我”的功能模块中查看和编辑您的个人资料。如您希望访问或更正其他无法直接访问或更正的个人信息，您均可以通过本政策提供的联系方式与我们联系。</p>
      <h4>（二）删除您的个人信息</h4>
      <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
      <p>1.如果我们处理个人信息的行为违反法律法规；</p>
      <p>2.如果我们收集、使用您的个人信息，却未征得您的同意；</p>
      <p>3.如果我们处理个人信息的行为违反了与您的约定；</p>
      <p>4.如果您不再使用我们的产品或服务，或您注销了账号；</p>
      <p>5.如果我们不再为您提供产品或服务。</p>
      <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
      <p>当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
      <h4>（三）改变您授权同意的范围</h4>
      <p>每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意，法律法规另有规定的除外。</p>
      <p>当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
      <h4>（四）注销账号</h4>
      <p>如您希望注销您的账号的，您需满足注销的相关条件。如您满足注销条件的，您可以通过本政策提供的联系方式与我们联系，我们将协助您注销账号。</p>
      <p>在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，删除或匿名化您的个人信息，法律法规另有规定的除外。</p>
      <h4>（五）响应您的上述请求</h4>
      <p>为了保障您的个人信息的安全，在您行使权利时，我们可能需要通过各种方式验证您的身份，然后再响应您的请求。</p>
      <p>原则上，我们会在接收到您的请求并确认您的身份后的15个工作日内响应您的请求。</p>
      <p>
        对于您的合理请求，我们原则上不收取费用。但对于多次重复、超出合理限度或产生不合理费用的请求，我们将按照实际情况收取一定的费用。对于无法实现、实现成本过大、无理由地重复请求、可能对他人造成风险的请求，我们可能会予以拒绝。</p>
      <p><span
        class="bold">当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到可以清除或进行匿名化。</span>
      </p>
      <p>在以下情形中，按照法律法规要求，我们将无法响应您的请求：</p>
      <p>1.与国家安全、国防安全有关的；</p>
      <p>2.与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>3.与犯罪侦查、起诉、审判和执行判决等有关的；</p>
      <p>4.有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
      <p>5.响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</p>
      <p>6.涉及商业秘密的。</p>
      <h3>六、我们如何处理儿童的个人信息</h3>
      <p><span class="bold">我们的产品和/或服务主要面向成人。在您接受我们的产品和/或服务时，我们推定您具有相应的民事行为能力。如果您为未成年人，您应当请您的父母或其他监护人仔细阅读本隐私政策并同意后，方可继续使用我们的产品和/或服务。如果我们识别出您是儿童的，我们将通知您的父母或其他监护人并要求您的监护人对是否同意本政策作出决定。</span>
      </p>
      <p><span class="bold">对于经父母或其他监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用、共享、转让、披露此信息。</span></p>
      <p><span class="bold">尽管当地法律和习俗对儿童的定义不同，但我们将不满 14 周岁的任何人均视为儿童。</span></p>
      <h3>七、您的个人信息如何跨境转移</h3>
      <p><span class="bold">基于业务需求、政府和司法监管等原因，我们可能将您的个人信息转移至其他国家或地区或接受来自境外主体的访问。如有跨境转移的需求，我们会根据相关法律法规的要求进行转移。</span></p>
      <h3>八、本政策如何更新</h3>
      <p>基于法律法规的要求及我们业务的变化，我们可能会不定期更新本政策。</p>
      <p>本政策变更后，我们将通过显著的方式通知您。未经您的同意，我们不会减损您根据本政策享有的任何权利。<span class="bold">请注意，在您同意变更后的隐私政策后，我们将按照变更后的隐私政策收集、存储、使用、保护您的个人信息。</span>
      </p>
      <p>如您需要了解本政策的历史版本，请通过本政策公布的联系方式与我们联系。</p>
      <h3>九、如何联系我们</h3>
      <p>您可以通过以下方式与我们联系，原则上我们会在15个工作日内响应您的请求：</p>
      <p>1.通过术康APP的客服功能与我们联系。</p>
      <p>2.联系电话：【400-993-2111】</p>
      <p>3.联系地址：【四川省成都市高新区天府大道北段1700号新世纪环球中心E2座1-3-1708】</p>
    </div>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

  export default {
    name: "privacy",
    data() {
      return {
      }
    },
    computed: {
      ...mapState({
      })
    },
    created() {
    },
    mounted(){
      document.title = "术康-隐私协议"
    }
  }
</script>

<style scoped lang="scss">
  .info_warp {
    padding: 16px 7px;
    /*font-family: "黑体";*/

    .inner {
      color: #000;

      p {
        word-wrap: break-word;
        font-size: 14px;
        text-indent: 2em;
        margin: 5px 0;
      }

      .bold {
        font-weight: bold;
      }

      .underLine {
        text-decoration: underline;
      }
    }

    .mgt15 {
      margin: 15px 0 0 0;

      div {
        margin: 5px 0;
      }
    }

    .bold {
      font-weight: bold;
    }
    .italic{
      font-style: italic;
    }
  }
  .info_warp .title{
    font-size: 24px;
    color: black;
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
  }

</style>
